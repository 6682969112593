<template>
  <NeoModal
    eager
    :max-width="400"
    :title-header="$t('changePassword')"
    scrollable
    @close="closeModal"
    @clickOutsideCallback="closeModal"
  >
    <center-box>
      <div class="neo-card">
        <v-form ref="registerForm" v-model="valid" lazy-validation>
          <h2 class="heading-size-4 mb-4">{{ $t("changePassword") }}</h2>
          <div class="neo-text-field">
            <div>{{ $t("currentPassword") }}</div>
            <v-text-field
              tabindex="1"
              v-model="loginPassword"
              flat
              solo
              outlined
              dense
              :append-icon="showCurrentPwd ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showCurrentPwd ? 'text' : 'password'"
              :rules="[rules.required, rules.error]"
              name="input-10-1"
              :label="$t('password')"
              @click:append="toggleEye"
              @keydown.enter="handleEnter"
              @keyup="clearError"
              autocomplete="password"
              :messages="errorMsgPwd"
            ></v-text-field>
          </div>
          <div class="neo-text-field">
            <div>{{ $t("newPassword") }}</div>
            <v-text-field
              autocomplete="off"
              tabindex="2"
              v-model="password"
              flat
              solo
              outlined
              dense
              :append-icon="showPwd ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPwd ? 'text' : 'password'"
              :rules="[
                rules.required,
                rules.min,
                rules.oneUpperCase,
                rules.oneLowerCase,
                rules.oneNumber,
              ]"
              name="input-10-1"
              :label="$t('newPassword')"
              counter
              :messages="passwordMsg"
              @click:append="toggleEye"
              @keydown.enter="handleEnter"
            ></v-text-field>
          </div>
          <div class="neo-text-field">
            <div>{{ $t("confirmPassword") }}</div>
            <v-text-field
              autocomplete="off"
              tabindex="3"
              v-model="verify"
              flat
              solo
              outlined
              dense
              :append-icon="showRepeatPwd ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showRepeatPwd ? 'text' : 'password'"
              :rules="[rules.required, passwordMatch]"
              name="input-10-1"
              :label="$t('confirmPassword')"
              counter
              @click:append="toggleEye2"
              @keydown.enter="handleEnter"
            ></v-text-field>
          </div>
          <div>
            <div
              v-if="pwdMsg"
              :class="`checkout-feedback-message${
                success ? '-success' : ''
              } mt-4`"
            >
              {{ pwdMsg }}
            </div>
          </div>

          <v-btn
            tabindex="4"
            ref="loginBtn"
            class="mt-4"
            block
            :disabled="waiting || !valid"
            color="primary"
            @click="validate"
            id="sign-in-button"
          >
            {{ $t("changePassword") }}
            <v-icon size="32" v-if="waiting" class="ml-1 waiting">
              mdi-tire
            </v-icon>
          </v-btn>
        </v-form>
      </div>
    </center-box>
  </NeoModal>
</template>

<script>
// import firebase from 'firebase/compat/app';
import CenterBox from "@/components/Templates/CenterBox";
import { firebase } from "@/helpers/firebase";
import NeoModal from "@/components/Molecules/NeoModal";

export default {
  name: "Register",
  components: {
    CenterBox,
    NeoModal,
  },
  data() {
    return {
      success: true,
      pwdMsg: "",
      initialStatus: { valid: false, verified: false },
      waiting: false,
      recaptchaOk: false,
      valid: true,
      username: "",
      lastName: "",
      telephone: {
        number: "",
        valid: false,
        country: undefined,
      },
      email: "",
      password: "",
      verify: "",
      loginPassword: "",
      loginEmail: "",
      userValid: true,
      showPwd: false,
      showCurrentPwd: false,
      showRepeatPwd: false,
      usernameTakenMsg: "",
      passwordMsg: "",
      emailMsg: "",
      errorMsgPwd: "",
      rules: {
        error: () => true || "",
        required: (value) => !!value || "Required.",
        oneUpperCase: (v) =>
          /[A-Z]/.test(v) || "Must contain at least one upper case",
        oneLowerCase: (v) =>
          /[a-z]/.test(v) || "Must contain at least one lower case",
        oneNumber: (v) => /[0-9]/.test(v) || "Must contain at least one number",
        strong: (v) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(v) ||
          "Password must contain at least one upper-case, one lower-case and one number",
        min: (v) => (v && v.length >= 9) || "Min 9 characters",
      },
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    onInput(formattedNumber, { number, valid, country }) {
      this.telephone.number = number.e164;
      this.telephone.valid = valid;
      this.telephone.country = country && country.name;
    },
    clearError() {
      this.errorMsgPwd = "";
      this.errorMsgEmail = "";
      this.rules.error = true;
    },
    toggleEye() {
      this.showPwd = !this.showPwd;
    },
    toggleEye2() {
      this.showRepeatPwd = !this.showRepeatPwd;
    },
    updateStatSharing() {
      //   this.$store.commit('updateStatSharing', this.shareStats ? 1 : 0)
      this.shareStats = this.shareStatsModel ? "1" : "0";
    },
    cancel() {
      this.dialog = false;
      this.$router.go(-1);
    },
    handleEnter() {
      this.$refs.loginBtn.$el.click();
    },
    validate() {
      // First check if the username exists in the database
      if (!this.recaptchaOk) {
        return;
      }
      if (this.$refs.registerForm.validate()) this.setNewPassword();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setNewPassword() {
      this.waiting = true;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.$store.state.user.email,
        this.loginPassword
      );
      this.$store.state.user
        .reauthenticateWithCredential(credential)
        .then(() => {
          const user = firebase.auth().currentUser;
          user
            .updatePassword(this.password)
            .then(() => {
              // this.$store.commit('updateUser', updatedUser);
              this.success = true;
              this.pwdMsg = "Password updated";
              setTimeout(() => {
                this.waiting = false;
                this.closeModal();
              }, 1500);

              // Update successful.
            })
            .catch((error) => {
              console.log("error changing password ", error);

              // An error ocurred
              // ...
            });
        })
        .catch((error) => {
          console.log("errro reauthenticating ", error, error.code);
          this.waiting = false;
          if (error.code === "auth/wrong-password") {
            this.success = false;
            this.pwdMsg = "Wrong password";
          }

          // An error ocurred
          // ...
        });
    },
  },
  mounted() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: () => {
          this.recaptchaOk = true;
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          console.log("bad recaptcha");
          // ...
        },
      }
    );

    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   'recaptcha-container',
    //   {
    //     size: 'normal',
    //     callback: () => {
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //       this.recaptchaOk = true;
    //     },
    //     'expired-callback': () => {
    //       // Response expired. Ask user to solve reCAPTCHA again.
    //       // ...
    //     },
    //   }
    // );

    window.recaptchaVerifier.render();
    window.recaptchaVerifier.verify();
  },
};
</script>
<style>
select::-ms-expand {
  display: block;
}
</style>
